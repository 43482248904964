import React, { useState, useEffect } from "react";
import InvoiceController from "./controllers";
import { Link } from "react-router-dom";

function ClientInvoices({ clienteId }) {
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    InvoiceController.getInvoicesByClientId(clienteId)
      .then((data) => {
        setInvoices(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [clienteId]);


  if (!invoices || invoices.length === 0) {
    return (
      <div className="text-gray-400 text-center py-3">
        No invoices found.
      </div>
    );
  }

  return (
    <div className="px-4">
      <h1 className="text-3xl font-bold text-center mb-6">Client Invoices</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {invoices.map((invoice) => (
          <div
            key={invoice._id}
            className="bg-white rounded-lg shadow-md overflow-hidden"
          >
            <div className="p-6">
              <h2 className="text-xl font-semibold text-[] mb-2">
                {invoice.invoiceName}
              </h2>
              <p className="text-sm text-gray-600 mb-2">
                Invoice Number: {invoice.invoiceNumber}
              </p>
              <p className="text-sm text-gray-600 mb-2">
                Date: {new Date(invoice.currentDate).toLocaleDateString()}
              </p>
              <p className="text-lg font-semibold text-gray-800 mb-4">
                Total: ${invoice.total.toFixed(2)}
              </p>
              <div className="flex justify-between items-center">
                <Link
                  to={`/invoice/${invoice._id}`}
                  className="text-blue-500 hover:text-blue-700 transition duration-300 ease-in-out"
                >
                  View Details
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ClientInvoices;
